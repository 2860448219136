import React from "react"
import {graphql} from "gatsby"
import {useGlobal} from "reactn"
import shuffle from "shuffle-array"
import MainLayout from "Layout/layout.jsx"
import Footer from "Layout//Footer/Footer.jsx"
import FlexibleContent from "Layout/FlexibleContent/FlexibleContent.jsx"
import PageTransition from "Layout/PageTransition/PageTransition.jsx"
import {dynamicContentShuffle} from "../helper/dynamicContentShuffle"
import {metaData} from "../helper/metaData"

const Page = ({data, location, pageContext}) => {
  const {page, employeesDE, employeesEN} = data
  let {relatedProjects, relatedStories} = data

  const [lang] = useGlobal("lang")
  const employeesIn = lang === "de" && employeesDE.edges || employeesEN.edges
  const employees = employeesIn

  const dynamicContent = dynamicContentShuffle(relatedProjects, relatedStories)

  relatedProjects = shuffle(relatedProjects.edges)
  relatedStories = shuffle(relatedStories.nodes).slice(0, 3)

  return (
    <MainLayout
      location={location}
      metaData={metaData(page)}
      pageContext={pageContext}
      translations={page.translations}
    >
      <PageTransition>
        {
          page.flex.content.length > 0 && (
            <FlexibleContent
              content={page.flex.content}
              dynamicContent={dynamicContent}
              location={location}
              pageName="index"
              relatedProjects={relatedProjects}
              relatedStories={relatedStories}
              shuffledEmployees={employees}
              slug={pageContext.slug}
            />
          )
        }
      </PageTransition>
      {page.footer && (<Footer teasers={page.footer.footerTeasers} headline={page.footer.headline} location={location} lang={pageContext.lang} translations={page.translations} />)}
    </MainLayout>
  )
}

export const pageQuery = graphql`query pagePageQuery($id: String!, $lang: String!) {
  page: wpContentNode(id: {eq: $id}) {
    ... on WpPage {
      title
      databaseId
      flex {
        content {
          __typename
          ...PageHeroSectionFragment
          ...PageHeroSectionSliderFragment
          ...PageServicesTeaserSectionFragment
          ...PageBasicSliderSectionFragment
          ...PageTeaserSectionFragment
          ...PageProgressSliderSectionFragement
          ...PageRelatedProjectsSectionFragment
          ...PageRelatedStorySectionFragment
          ...PageTextItemsColumnsSectionFragment
          ...PageJobOverviewSectionFragment
          ...PageJobTeaserSectionFragment
          ...PageContactPersonsSectionFragment
          ...PageLocationSectionFragment
          ...PageInquirySectionFragment
          ...PageHeadlineSectionFragment
          ...PageTextSectionFragment
          ...PageTextAndImagesColumnSectionFragment
          ...PageProjectOverviewSectionFragment
          ...PageCompetenceSectionFragment
          ...PageTeamOverviewSectionFragment
          ...PageTimelineSliderSectionFragement
          ...PageNewsOverviewSectionFragment
          ...PageCurrentNewsTeaserSectionFragment
          ...PageFeaturedContentSectionFragment
          ...PageDynamicContentSectionFragment
          ...PageCustomerSurveySectionFragment
          ...PageJobApplicationSectionFragment
          ...PageJobApplicationFormFragment
          ...PageEmbedSectionFragment
        }
      }
      translations {
        uri
        language {
          slug
        }
      }
      ...PageFooterFragment
    }
  }
  relatedProjects: allWpProject(filter: {id: {ne: $id}, language: {slug: {eq: $lang}}, title: {ne: "DO NOT DELETE"}}) {
    edges {
      node {
        title
        slug
        uri
        date
        databaseId
        nodeType
        postImages {
          postImages {
            portaitImage {
              sourceUrl
              mediaDetails {
                height
                width
              }
              altText
              title
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 862, quality: 80, placeholder: NONE, layout: CONSTRAINED)
                }
              }
            }
            landscapeImage {
              sourceUrl
              mediaDetails {
                height
                width
              }
              altText
              title
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 80, placeholder: NONE, layout: FULL_WIDTH)
                }
              }
            }
          }
        }
      }
    }
  }
  relatedStories: allWpPost(filter: {id: {ne: $id}, language: {slug: {eq: $lang}}, title: {ne: "DO NOT DELETE"}}) {
    nodes {
      title
      slug
      uri
      date
      databaseId
      nodeType
      featuredImage {
        node {
          sourceUrl
          mediaDetails {
            height
            width
          }
          altText
          title
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 80, placeholder: NONE, layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
  employeesDE: allWpEmployee(filter: {title: {nin: ["DO NOT DELETE", "Karriere bei EHAM"]}, language: {slug: {eq: "de"}}}, sort: {order: DESC, fields: date}) {
    edges {
      node {
        language {
          slug
        }
        databaseId
        title
        date
        slug
        employeeInfo {
          employee {
            name
            email
            phone
            competence {
              ... on WpCompetence {
                databaseId
              }
            }
            function
            image {
              sourceUrl
              mediaDetails {
                height
                width
              }
              altText
              title
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 550, quality: 80, placeholder: BLURRED, layout: CONSTRAINED)
                }
              }
            }
          }
        }
        departments {
          nodes {
            name
            termTaxonomyId
            taxonomy {
              node {
                name
              }
            }
          }
        }
      }
    }
  }
  employeesEN: allWpEmployee(filter: {title: {nin: ["DO NOT DELETE", "Career with EHAM"]}, language: {slug: {eq: "en"}}}, sort: {order: DESC, fields: date}) {
    edges {
      node {
        language {
          slug
        }
        databaseId
        title
        date
        slug
        employeeInfo {
          employee {
            name
            email
            phone
            competence {
              ... on WpCompetence {
                databaseId
              }
            }
            function
            image {
              sourceUrl
              mediaDetails {
                height
                width
              }
              altText
              title
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 550, quality: 80, placeholder: BLURRED, layout: CONSTRAINED)
                }
              }
            }
          }
        }
        departments {
          nodes {
            name
            termTaxonomyId
            taxonomy {
              node {
                name
              }
            }
          }
        }
      }
    }
  }
}
`

export default Page
